import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../../../components/Layout';
import SEO from '../../../../components/seo';
import Content from '../../../../components/Content';
import Block from '../../../../components/Block';
import Hero from '../../../../components/Hero';
import Anchors from '../../../../components/Anchors';
import Subnavigation from '../../../../components/Subnavigation';
import PersonBadge from '../../../../components/PersonBadge';
import ContentImage from '../../../../components/ContentImage';
import CaseStudyAttributions from '../../../../components/CaseStudyAttributions';
import Breadcrumb from '../../../../components/Breadcrumb';
import {graphql, useStaticQuery} from 'gatsby';

const InterferometryInTransportCZ = ({pageContext}) => {
	const caseStudyAttributions = {
		period: pageContext.caseStudy.period,
		region: 'Czechia',
		client: [
			{
				href: 'https://www.rsd.cz/',
				text: 'RSD (Czech Motorway and Highway Directorate)',
			},
			{
				href: 'https://www.spravazeleznic.cz/',
				text: 'SŽ (Czech Railway Infrastructure Administration)',
			},
			{
				href: 'https://www.tacr.cz/',
				text: 'TACR (Czech Technological Agency)',
			},
		],
		// organisation: null,
		// projectLeader: 'GISAT',
	};
	const data = useStaticQuery(graphql`
		{
			background: file(
				relativePath: {eq: "pages/work/caseStudies/highway.jpg"}
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
		}
	`);

	return (
		<Layout>
			<SEO title="Interferometric solutions for transportation sector in Czech Republic" />
			<Hero
				backgroundImageFluid={data.background.childImageSharp.gatsbyImageData}
			>
				<Breadcrumb crumbs={pageContext?.crumbs} />
				<h1>
					Interferometric solutions for transportation sector in Czech Republic
				</h1>
			</Hero>
			<Block light>
				<Content>
					<Subnavigation>
						<Anchors>
							<a href={'#problem'}>The problem</a>
							<a href={'#solution'}>Our solution</a>
							<a href={'#results'}>Results</a>
						</Anchors>
						<PersonBadge
							note={pageContext.caseStudy.contactNote}
							email={pageContext.caseStudy.contactPerson}
						/>
					</Subnavigation>
				</Content>
			</Block>
			<Block light className={'gst-attribution-block'}>
				<Content>
					<CaseStudyAttributions {...caseStudyAttributions} />
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'problem'}>The problem</h2>
					<p>
						Series of R&D and commercial contracts have been developing and
						implementing satellite interferometry solutions within the
						transportation infrastructure sector. The clients are institutional
						operators responsible for construction and maintenance of road and
						railway infrastructure in Czechia. They deal with adverse
						deformations of infrastructure objects such as bridges, embankments
						or ground and slope instabilities posing a risk to exposed
						infrastructure elements.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'solution'}>Our solution</h2>
					<p>
						Gisat has developed and implemented several tailored solutions based
						on state-of-the-art persistent scatterers interferometry (PSI)
						algorithms, decision support tools and frameworks.
					</p>
					<p>
						Gisat has developed optimal design of single-headed corner
						reflectors certified for installation on bridge objects in Czechia.
						Subsequently, the design has been further elaborated and double-head
						corner reflectors of our proprietary design are being used to
						support monitoring slope instabilities around D8 highway.
					</p>
					<p>
						We have developed certified methodologies for assessment of PSI
						feasibility in context of deformation risks to transport
						infrastructure and bridges. Our solution helps in identification of
						adverse spatial and temporal deformation patterns in the D8 highway
						area threatened by landslides and along the 120km long railway
						segment in west bohemia.
					</p>
					{/*<ContentImage*/}
					{/*	fluid={null}*/}
					{/*	legend={`TKO na mostě v ově,*/}
					{/*		TKO na D8,*/}
					{/*		Ukázka z reportu pro RSD,*/}
					{/*		Ukázka z aplikace pro SZDC`}*/}
					{/*/>*/}
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'results'}>Results</h2>
					<p>
						MT InSAR has proved as cost-effective solution that brings benefits
						by complementing traditional monitoring techniques and thanks to its
						ability to identify deformation hot spots and risky temporal
						deformation patterns both from retrospective and proactive mapping.
					</p>
				</Content>
			</Block>
		</Layout>
	);
};

InterferometryInTransportCZ.propTypes = {
	pageContext: PropTypes.object.isRequired,
};
export default InterferometryInTransportCZ;
